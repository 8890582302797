<template>
  <v-app>
    <v-main class="appbackground">
      <div class="glassEffect"></div>

      <div class="contentContainer">
        <div class="text-center my-14">
          <p class="titleText">Explore NFTs</p>
          <p class="text-white subtitleText">
            Explore all music NFTs releases from Gummint
          </p>
        </div>
        <div class="mx-auto exploreContainer">
          <ExploreTab class="mx-auto"></ExploreTab>
        </div>
      </div>
      <BottomFooter class="footer" />

    </v-main>
  </v-app>
</template>

<script>
import ExploreTab from "../components/ExploreTab.vue";
import BottomFooter from "../components/BottomFooter.vue";
import {onMounted} from "vue";

export default {
  name: "HomePage",

  components: {
    ExploreTab,
    BottomFooter,
  },

  setup(){
    onMounted(() => {
      window.scrollTo(0, 0);
    });
  }
  
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.titleText {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: rgb(255, 123, 0);
}

.contentContainer {
  position: relative;
  z-index: 9;
  flex: 1;
  min-height: 100vh;
}

.exploreContainer {
  min-height: 600px;
}


.subtitleText {
  font-size: 30px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}
/* .appbackground {
  background-color: #11001d;
} */
</style>
