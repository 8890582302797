<template>
  <v-main class="mainContainer">
    <div v-if="isMainLoading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>

    <div v-if="!isMainLoading">
      <div class="contentContainer mb-15">
        <div
          class="backgroundGradient"
          :style="`background:
        ${bannerBackground}`"
        >
          <v-container class="mt-10">
            <v-row
              class="mx-auto"
              justify="center"
              align="center"
              style="max-width: 1200px"
            >
              <v-col>
                <v-card
                  max-height="400"
                  max-width="400"
                  class="playcard"
                  color="transparent"
                >
                  <div class="text-center">
                    <img class="songImage" :src="metadata.image" alt="logo" />

                    <!-- <div class="mt-3 mx-4 playerColor"></div> -->

                    <div
                      class="d-flex flex-row justify-space-around align-center mx-10"
                    >
                      <!-- <v-icon small class="gradientColor">mdi-rewind</v-icon> -->
                      <!-- <v-icon color="white" class="playbutton" @click="!play"
                      >mdi-play-circle</v-icon
                    > -->
                      <!-- <v-icon class="gradientColor">mdi-fast-forward</v-icon> -->
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <p class="text-white text-h2">
                  {{ metadata.name }}
                </p>
                <p class="text-white text-h6">
                  {{ metadata.collectionGenre }}
                </p>

                <p class="text-white subText mt-1">
                  By: {{ metadata.artist1 }}
                </p>

                <p class="text-white subText mt-1">
                  {{ metadata.royaltyType }} royalty:
                  {{ metadata.royaltyPercentage }} %
                </p>
                <p class="text-white subText mt-1">
                  Life of rights: {{ metadata.lifeOfRights }}
                </p>
                <p class="text-white subText mt-1">
                  Edition:
                  {{
                    metadata.attributes && metadata.attributes.length > 0
                      ? metadata.attributes.find(
                          (attr) => attr.trait_type === "Edition"
                        )?.value || "N/A"
                      : "N/A"
                  }}
                </p>
                <p class="text-white subText mb-6 mt-1">
                  Song released: {{ metadata.songReleaseDate }}
                </p>

                <div
                  v-if="!isOwner && isListedForSale"
                  class="d-flex mt-6"
                  style="gap: 20px"
                >
                  <div>
                    <p class="text-white subText">Price</p>
                    <p class="text-white text-h5">
                      R {{ songBackendData.price }}
                    </p>
                  </div>
                </div>
                <div v-else class="pt-6"></div>
                <div v-if="isOwner" class="mt-1">
                  <p class="text-white text-h6">
                    NFT Owner
                    <v-icon color="blue" size="25" class="mr-1 ownerIcon"
                      >mdi-check-decagram</v-icon
                    >
                  </p>
                  <v-btn
                    v-if="isOwner && !isListedForSale"
                    size="large"
                    class="buyBtn px-4 mt-5"
                    :disabled="buyloading"
                    @click="showModal = true"
                  >
                    SELL THIS NFT
                    <v-progress-circular
                      v-if="buyloading"
                      class="ml-2"
                      indeterminate
                      color="white"
                      :size="25"
                    ></v-progress-circular>
                  </v-btn>
                  <!-- <div v-if="isOwner && isListedForSale">
                    <p class="mt-5 text-white text-h6">
                      This NFT is open for buyers
                    </p>
                    <v-btn
                      size="large"
                      class="buyBtn px-4 mt-5"
                      @click="cancelListing"
                      :disabled="cancelLoading"
                    >
                      Cancel listing
                      <v-progress-circular
                        v-if="cancelLoading"
                        class="ml-2"
                        indeterminate
                        color="white"
                        :size="25"
                      ></v-progress-circular>
                    </v-btn>
                  </div> -->
                </div>
                <div v-else>
                  <form
                    id="payfastForm"
                    ref="paymentForm"
                    :action="payfastURL"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="merchant_id"
                      :value="payfastMerchantId"
                    />
                    <input type="hidden" name="subscription_type" value="2" />
                    <input
                      type="hidden"
                      name="merchant_key"
                      :value="payfastMerchantKey"
                    />
                    <input
                      type="hidden"
                      name="amount"
                      :value="songBackendData.price"
                    />
                    <input
                      type="hidden"
                      name="item_name"
                      :value="metadata.name"
                    />
                    <input
                      type="hidden"
                      name="return_url"
                      :value="`https://gummintprod.netlify.app/success/${hashedId}/${nftMarketplaceDetails.tokenId}`"
                    />
                    <input
                      type="hidden"
                      name="cancel_url"
                      :value="`https://gummintprod.netlify.app/collection/${collectionId}/${nftBackendId}/${nftMarketplaceDetails.tokenId}`"
                    />
                    <input
                      type="hidden"
                      name="notify_url"
                      :value="`${backendUrl}transactions/buy/`"
                    />
                    <input type="hidden" name="email_address" :value="email" />
                    <input type="hidden" name="m_payment_id" value="01AB" />
                    <input
                      type="hidden"
                      name="item_name"
                      :value="songBackendData.name"
                    />
                    <input
                      type="hidden"
                      name="item_description"
                      value="A test product"
                    />
                    <input
                      type="hidden"
                      name="custom_int1"
                      :value="nftMarketplaceDetails.tokenId"
                    />
                    <input type="hidden" name="custom_int3" :value="userId" />
                    <input
                      type="hidden"
                      name="custom_int4"
                      :value="nftBackendId"
                    />
                    <input type="hidden" name="custom_str1" :value="accounts" />
                    <input
                      type="hidden"
                      name="custom_str2"
                      :value="signature"
                    />
                    <input
                      type="hidden"
                      name="custom_str3"
                      :value="userAuthToken"
                    />
                    <input
                      type="hidden"
                      name="custom_str4"
                      :value="nftMarketplaceDetails.seller"
                    />
                    <!-- <input type="hidden" name="signature" :value="signature" /> -->
                    <!-- <input type="submit" /> -->
                    <v-btn
                      v-if="nftMarketplaceDetails.listedForSale == false"
                      size="large"
                      class="buyBtn px-4 mt-5 mx-auto"
                      disabled="true"
                    >
                      NFT Sold
                    </v-btn>
                    <v-btn
                      v-else-if="
                        nftMarketplaceDetails.listedForSale == true &&
                        isSeller &&
                        isAuthenticated
                      "
                      size="large"
                      class="buyBtn px-4 mt-5 mx-auto"
                      @click="cancelListing"
                      :disabled="cancelLoading"
                    >
                      Cancel resale
                      <v-progress-circular
                        v-if="cancelLoading"
                        class="ml-2"
                        indeterminate
                        color="white"
                        :size="25"
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      v-else-if="
                        isAuthenticated &&
                        nftMarketplaceDetails.listedForSale == true
                      "
                      size="large"
                      type="submit"
                      class="buyBtn px-4 mt-5 mx-auto"
                      :disabled="buyloading"
                    >
                      BUY NFT
                      <v-progress-circular
                        v-if="buyloading"
                        class="ml-2"
                        indeterminate
                        color="white"
                        :size="25"
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      v-else
                      size="large"
                      class="buyBtn px-4 mt-5 mx-auto"
                      @click="redirectToLogin"
                    >
                      BUY NFT
                    </v-btn>
                    <!-- <a href="https://www.payfast.co.za/eng/recurring/update/">Update the card for your subscription</a> -->
                    <!-- <v-btn
                    
                    size="large"
                    @click="updateCard"
                    class="buyBtn px-4 mt-5 mx-auto"
                  >
                    UPDATE CARD
                    
                  </v-btn> -->
                    <!-- <v-btn @click="performPayfastPayment"></v-btn> -->
                  </form>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-card
          max-width="1200"
          class="mx-auto detailsTab mt-10"
          flat
          color="transparent"
        >
          <v-tabs
            centered
            center-active
            color="rgb(255, 123, 0)"
            style="font-size: 22px"
            v-model="tab"
          >
            <v-tab class="tabStyle">Details</v-tab>
            <v-tab class="tabStyle">Streaming</v-tab>
            <v-tab class="tabStyle">Sales</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div v-if="tab == 2" style="min-height: 500px" class="px-1">
                <div
                  v-for="owner in owners"
                  :key="owner"
                  class="royaltystats py-4 my-3"
                >
                  <div
                    class="d-flex align-center justify-space-between px-2 mr-4"
                  >
                    <div class="salesTab">
                      <div class="d-flex align-center justify center">
                        <v-avatar size="30" class="mx-2">
                          <v-img
                            :src="mediaUrl + owner.toUser.profilePicture"
                            alt="profile picture"
                          ></v-img>
                        </v-avatar>
                        <p class="text-white ownerAddressText">
                          {{ owner.toUser.username }}
                        </p>
                        <v-icon
                          v-if="owner.toUser.publicKey == nftOwner.owner_of"
                          color="blue"
                          size="20"
                          class="ml-2"
                          >mdi-check-decagram</v-icon
                        >
                      </div>
                    </div>
                    <p class="text-grey dateText">
                      {{ owner.date }}
                      <span class="ml-2 text-grey">{{
                        owner.formattedTime
                      }}</span>
                    </p>
                    <p class="text-white ownerAddressText">
                      R {{ owner.nft.price }}
                    </p>
                  </div>
                </div>
                <div v-if="owners.length == 0">
                  <div class="d-flex justify-center align-center mt-10">
                    <p
                      class="text-h5 text-white"
                      style="width: 400px; height: 500px; text-align: center"
                    >
                      No sales have been made yet for this NFT
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="tab == 0">
                <div v-if="mainArtist">
                  <div class="text-white detailsContainer mt-6">
                    <h2 class="text-h5 mb-3 subHeading">Artists</h2>
                    <div>
                      <v-row class="artistContainer">
                        <v-col>
                          <router-link
                            :to="`/artist/${mainArtist.id}`"
                            class="d-flex custom-link"
                            style="gap: 10px"
                          >
                            <img
                              :src="mediaUrl + mainArtistPicture"
                              alt="Artist image"
                              class="artistImage"
                            />
                            <h3 class="text-h6 pb-2">
                              {{ mainArtist.name }}
                            </h3>
                          </router-link>
                        </v-col>
                      </v-row>
                      <p class="mb-6">
                        {{ mainArtist.description }}
                      </p>
                    </div>
                    <div
                      v-for="featuredArtist in featuredArtists"
                      :key="featuredArtist"
                    >
                      <v-row class="artistContainer">
                        <v-col>
                          <router-link
                            :to="`/artist/${featuredArtist.id}`"
                            class="d-flex custom-link"
                            style="gap: 10px"
                          >
                            <img
                              :src="
                                mediaUrl + featuredArtist.user.profilePicture
                              "
                              alt="Artist image"
                              class="artistImage"
                            />
                            <h3 class="text-h6 pb-2">
                              {{ featuredArtist.name }}
                            </h3>
                          </router-link>
                        </v-col>
                      </v-row>
                      <p class="mb-6">
                        {{ featuredArtist.description }}
                      </p>
                    </div>
                    <h3 class="text-h5 my-3 subHeading">Ownership Benefits</h3>
                    <p class="mb-6">
                      By owning an "{{ metadata.name }}" NFT, you not only
                      support song but also earn
                      {{ metadata.royaltyPercentage }}% share of the streaming
                      royalties every time the song is played. As more people
                      listen to the song, the value of your NFT may increase.
                      Additionally, NFT holders can possibly receive exclusive
                      access and deals to virtual meet-and-greets, events and
                      merchandise.
                    </p>
                    <h3 class="text-h5 my-3 subHeading">Streaming Platforms</h3>
                    <p>Available on all streaming platforms</p>
                    <iframe
                      class="songPlayer mt-3"
                      :src="songLink"
                      :title="songBackendData.name"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <!-- <ul class="mb-6">
                      <li
                        v-for="platform in metadata.streamingPlatforms"
                        :key="platform"
                      >
                        {{ platform }}
                      </li>
                    </ul> -->

                    <h3 class="text-h5 my-3 subHeading">
                      Terms and Conditions
                    </h3>
                    <p class="mb-6">
                      By buying this NFT you accept that you have read and
                      agreed to our
                      <router-link class="text-blue" to="/terms">
                        terms and conditions</router-link
                      >
                    </p>
                  </div>
                </div>
                <div v-else class="contentLoader">
                  <v-progress-circular
                    indeterminate
                    color="rgb(255, 123, 0)"
                    :size="50"
                  ></v-progress-circular>
                </div>
              </div>

              <div v-if="tab == 1">
                <v-container class="mt-10">
                  <EarningsIndicator
                    :ownershipPercentage="metadata.royaltyPercentage"
                    :totalEarnings ="songBackendData.collection.earnings"
                    :totalStreams = "songBackendData.collection.streamQuantity"
                  />
                  <v-row justify="center" class="mt-8">
                    <StreamingChart
                      :collectionId="collectionId"
                    ></StreamingChart>
                  </v-row>
                  <p class="text-white text-h4 mb-8">Payout Calculator</p>
                  <p class="text-white text-h5 mb-3">
                    Enter your streaming royalty percentage to calculate
                    earnings per stream. Specify the number of streams to
                    estimate your total earnings.
                  </p>
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" md="8">
                      <v-card class="glassmorphism text-white">
                        <v-card-text class="mx-auto text-center">
                          <div class="mx-auto">
                            <v-text-field
                              class="mx-10"
                              :clearable="true"
                              variant="underlined"
                              v-model="royaltyPercentage"
                              label="Streaming Royalty Percentage (%)"
                            ></v-text-field>
                          </div>
                          <div class="mx-auto">
                            <v-text-field
                              class="mx-10"
                              :clearable="true"
                              variant="underlined"
                              v-model="numberOfStreams"
                              type="number"
                              label="Number of Streams"
                            ></v-text-field>
                          </div>

                          <v-row
                            v-for="(rate, platform) in streamingRates"
                            :key="platform"
                            align="center"
                            justify="center"
                          >
                            <v-col cols="3">
                              <img
                                :src="getPlatformImage(platform)"
                                alt="Platform Image"
                                class="platform-image"
                              />
                            </v-col>
                            <v-col>
                              <p class="text-h6">{{ platform }}</p>
                            </v-col>

                            <v-col>
                              <p class="text-h6">
                                R {{ calculatePayout(rate) }}
                              </p>
                            </v-col>
                          </v-row>

                          <v-divider class="my-4"></v-divider>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col cols="6">
                                  <p class="text-h5">Total Payout:</p>
                                </v-col>
                                <v-col cols="6">
                                  <p class="text-h5">
                                    R {{ calculateTotalPayout }}
                                  </p>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>

      <BottomFooter class="footer" />
    </div>

    <v-dialog v-model="showModal" width="600">
      <v-card class="rounded-xl glassmorphism elevation-15 text-white">
        <p class="text-h4 text-center mt-3">
          {{ currentTitle }}
        </p>
        <div class="mx-auto">
          <v-img
            :src="metadata.image"
            alt="logo"
            height="200"
            width="200"
            class="mt-4 rounded-lg"
            contain
          ></v-img>
        </div>
        <p class="text-white text-h6 text-center">
          {{ metadata.name }}
        </p>
        <p class="text-white text-subtitle-1 mb-6 mt-1 text-center">
          By: {{ metadata.artist1 }}
        </p>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <p class="text-center text-h5">
                You have to first approve this NFT to be listed for sale
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="buyBtn mx-auto px-4"
                :disabled="approveLoading"
                @click="approveNFT"
              >
                Approve
                <v-progress-circular
                  v-if="approveLoading"
                  class="ml-2"
                  indeterminate
                  color="white"
                  :size="23"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-window-item>

          <v-window-item :value="2">
            <div class="pa-4 text-center">
              <p class="modalSubtitleText text-center text-white">
                Put your music NFT up for Sale on the market, set your selling
                price below (you are selling your royalty share)
              </p>
              <v-card-text>
                <v-form class="text-white">
                  <v-text-field
                    v-model="sellPrice"
                    variant="underlined"
                    label="Sell Price"
                    :clearable="true"
                    prepend-icon="mdi-cart-outline"
                    required
                  ></v-text-field>
                  <div class="text-center">
                    <v-btn
                      class="mx-auto buyBtn mb-3"
                      :disabled="relistLoading"
                      @click="listNFT"
                      >List for sale
                      <v-progress-circular
                        v-if="relistLoading"
                        indeterminate
                        color="white"
                        :size="23"
                      ></v-progress-circular>
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { Magic } from "magic-sdk";
import NFTPlay from "../components/NFTPlay.vue";
import { ref, onMounted, computed, watch, reactive } from "vue";
import Marketplace from "../Marketplace.json";
import { ethers } from "ethers";
import CreateNft from "../CreateNft.json";
import { useRoute } from "vue-router";
import BottomFooter from "../components/BottomFooter.vue";
import Chatbot from "../components/Chatbot.vue";
import axios from "axios";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import { GelatoRelay } from "@gelatonetwork/relay-sdk";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Network, Alchemy } from "alchemy-sdk";
import { prominent, average } from "color.js";
import Freecurrencyapi from "@everapi/freecurrencyapi-js";
import router from "../router";
import EarningsIndicator from "../components/EarningsIndicator.vue";
import PredictionChart from "../components/PredictionChart.vue";
import StreamingChart from "../components/StreamingChart.vue";


const crypto = require("crypto");

export default {
  name: "NFTPage",

  components: {
    NFTPlay,
    BottomFooter,
    Chatbot,
    StreamingChart,
    EarningsIndicator,
    PredictionChart,
  },

  setup() {
    // route params
    const route = useRoute();
    const nftTokenId = route.params.nftTokenId;
    const nftBackendId = route.params.nftBackendId;
    const collectionId = route.params.id;
    const mediaUrl = process.env.VUE_APP_MEDIA_API_URL;

    const hashedId = crypto
      .createHash("md5")
      .update(nftTokenId + "successpage")
      .digest("hex");

    const backendUrl = process.env.VUE_APP_BACKEND_API_URL;

    // display loading
    const mainLoading = ref(true);
    const buyloading = ref(false);
    const relistLoading = ref(false);
    const cancelLoading = ref(false);
    const approveLoading = ref(false);
    const currentNftLoading = ref(false);

    const nftOwner = ref({});
    const tab = ref(null);
    const play = ref(false);
    const nftMarketplaceDetails = ref({});
    const metadata = ref({});
    const userAuth = useAuthenticationStore();
    const owners = ref([]);
    const { email } = storeToRefs(userAuth);
    const { accounts } = storeToRefs(userAuth);
    const { isAuthenticated } = storeToRefs(userAuth);
    const { userId } = storeToRefs(userAuth);
    const { userAuthToken } = storeToRefs(userAuth);
    const { isAdmin } = storeToRefs(userAuth);
    const price = ref();
    const sellPrice = ref();
    const showModal = ref(false);
    const signature = ref("");
    const paymentForm = ref(null);
    const songBackendData = ref({});
    const featuredArtists = ref([]);
    const songLink = ref(null);
    const mainArtist = ref({});
    const mainArtistPicture = ref(null);
    const royaltyPercentage = ref(null);
    const numberOfStreams = ref(null);
    const { clearCookies } = userAuth;

    const payfastMerchantId = process.env.VUE_APP_PAYFAST_MERCHANT_ID;
    const payfastMerchantKey = process.env.VUE_APP_PAYFAST_MERCHANT_KEY;
    const payfastPhrase = process.env.VUE_APP_PHRASE;
    const payfastURL = process.env.VUE_APP_PAYFAST_URL;

    let usdzar = 0;

    const bannerBackground = ref({});

    const step = ref(1);

    const streamingRates = {
      "Tidal Music": 0.01284,
      "Apple Music": 0.008,
      "Amazon Music": 0.00402,
      Spotify: 0.00318,
      "YouTube Music": 0.002,
      Pandora: 0.00133,
      Deezer: 0.0011,
    };

    const freecurrencyapi = new Freecurrencyapi(
      process.env.VUE_APP_CURRENCY_API_KEY
    );

    const settings = {
      apiKey: process.env.VUE_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
      network: Network.MATIC_MAINNET, // Replace with your network.
    };

    const alchemy = new Alchemy(settings);

    onMounted(() => {
      window.scrollTo(0, 0);
      mainLoading.value = true;
      const route = useRoute();
      checkLogin();
      getCurrentNFT(route.params.nftTokenId);
      songRefetch();
      refetch();
      paySignature();
      freecurrencyapi
        .latest({
          base_currency: "USD",
          currencies: "ZAR",
        })
        .then((response) => {
          usdzar = response.data.ZAR;
        });
      const script = document.createElement("script");
      script.src = "https://www.payfast.co.za/onsite/engine.js";
      document.head.appendChild(script);
    });

    const currentTitle = computed(() => {
      switch (step.value) {
        case 1:
          return "List NFT for Sale";
        case 2:
          return "Set your selling price";
        default:
          return "Account created";
      }
    });

    const {
      result: songResult,
      loading: songLoading,
      error: songError,
      refetch: songRefetch,
    } = useQuery(
      gql`
        query dropsById($id: Int!) {
          dropsById(id: $id) {
            id
            name
            nfttokenid
            image
            nftsound
            price
            royaltyType
            royaltyPercentage
            collection {
              earnings
              streamQuantity
              artist {
                id
                name
                description
                user {
                  profilePicture
                }
              }
              link
              featuredArtists {
                id
                name
                description
                user {
                  profilePicture
                }
              }
            }
          }
        }
      `,
      {
        id: Number(nftBackendId),
      }
    );

    watch(songResult, (value) => {
      songBackendData.value = value.dropsById[0];
      featuredArtists.value = value.dropsById[0].collection.featuredArtists;
      mainArtist.value = value.dropsById[0].collection.artist;
      mainArtistPicture.value = mainArtist.value.user.profilePicture;
      if (songBackendData.value) {
        songLink.value = songBackendData.value.collection.link;
        console.log(songLink.value);
        royaltyPercentage.value = songBackendData.value.royaltyPercentage;
        numberOfStreams.value = 100000;
      }
    });

    const { result, loading, error, refetch } = useQuery(
      gql`
        query transactionsByToken($nft: Int!) {
          transactionsByToken(nft: $nft) {
            id
            price
            time
            nft {
              price
            }
            toUser {
              username
              publicKey
              profilePicture
            }
            date
          }
        }
      `,
      {
        nft: Number(nftBackendId),
      }
    );

    watch(result, (value) => {
      if (value.transactionsByToken) {
        const formattedOwners = value.transactionsByToken.map((transaction) => {
          // Format the time property to display only hours and minutes
          const time = transaction.time.substring(0, 5);
          return {
            ...transaction,
            formattedTime: `${time}`,
          };
        });
        owners.value = formattedOwners;
      }
    });

    const redirectToLogin = () => {
      // Assuming your login page route is named "login"
      router.push({ name: "Login" });
    };

    const paySignature = () => {
      const signatureData = userAuthToken.value;
      signature.value = crypto
        .createHash("md5")
        .update(signatureData + process.env.VUE_APP_PHRASE)
        .digest("hex");
    };

    const checkLogin = async () => {
      if (isAdmin.value == true) {
        try {
          // Request account access if needed
          await window.ethereum.request({ method: "eth_requestAccounts" });
          // Get the user's accounts
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const address = await signer.getAddress();
          // Check if the MetaMask address matches the expected address
          const expectedAddress = process.env.VUE_APP_WALLET_ADDRESS;
          if (address.toLowerCase() !== expectedAddress.toLowerCase()) {
            alert(
              "Login failed. MetaMask address does not match admin address."
            );
            clearCookies();
          }
        } catch ({ error }) {
          alert("Authentication failed.");
          clearCookies();
        }
      } else {
        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);

        try {
          const isLoggedIn = await magic.user.isLoggedIn();
          if (!isLoggedIn) {
            clearCookies();
          }
        } catch {
          // Handle errors if required!
        }
      }
    };

    async function checkTaskStatus(
      taskId,
      maxRetries = 15,
      pollingIntervalMs = 3000
    ) {
      let retries = 0;

      while (retries < maxRetries) {
        try {
          const response = await axios.get(
            `https://api.gelato.digital/tasks/status/${taskId}`
          );
          const taskState = response.data.task.taskState;

          if (taskState === "ExecSuccess") {
            return true; // Task was successful, exit the loop and return
          } else if (taskState === "Cancelled") {
            return false; // Task was cancelled, exit the loop and return
          }
          // Task is still pending, wait for the next polling interval
          await new Promise((resolve) =>
            setTimeout(resolve, pollingIntervalMs)
          );
          retries++;
        } catch (error) {
          console.error("Error checking task status:", error);
          return false;
        }
      }

      console.error("Max retries reached, task is still pending.");
      return false;
    }

    async function approveNFT() {
      if (!approveLoading.value) {
        approveLoading.value = true;
        try {
          const relay = new GelatoRelay();
          const customNodeOptions = {
            rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL,
            chainId: process.env.VUE_APP_CHAINID,
          };
          const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
            network: customNodeOptions,
          });
          const provider = new ethers.BrowserProvider(magic.rpcProvider);
          const signer = await provider.getSigner();
          const signerAddress = signer.address;
          let market = JSON.parse(Marketplace);
          let createNft = JSON.parse(CreateNft);

          let nftcontract = new ethers.Contract(
            createNft.address,
            createNft.abi,
            signer
          );
          let approvePayload = await nftcontract.approve.populateTransaction(
            market.address,
            nftTokenId
          );
          const approveRelayRequest = {
            chainId: (await provider.getNetwork()).chainId,
            target: createNft.address,
            data: approvePayload.data,
            user: signerAddress,
          };
          const approveRelayResponse = await relay.sponsoredCallERC2771(
            approveRelayRequest,
            signer,
            process.env.VUE_APP_RELAYER_API_KEY
          );
          const isApproveRelaySuccessful = await checkTaskStatus(
            approveRelayResponse.taskId
          );
          if (!isApproveRelaySuccessful) {
            throw new Error("Error please try again");
          } else {
            step.value = 2;
          }
          approveLoading.value = false;
        } catch (error) {
          approveLoading.value = false;
          alert("Network error please try again");
        }
      }
    }

    async function updateCard() {
      const result = await axios
        .post(`https://sandbox.payfast.co.za/eng/recurring/update`)
        .then((res) => {
          return console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    async function updateFiatListingPrice() {
      try {
        const formData = new FormData();
        formData.append("price", sellPrice.value);
        const headers = {
          Authorization: "Token " + userAuthToken.value,
        };

        const response = await axios.patch(
          process.env.VUE_APP_BACKEND_API_URL +
            `collections/updatesongprice/${nftBackendId}/`,
          formData,
          { headers }
        );
        // Handle the response data here if needed
      } catch (error) {
        console.error("Error:", error);
        // Handle the error here if needed
      }
    }

    async function listNFT() {
      if (!relistLoading.value) {
        relistLoading.value = true;
        try {
          const relay = new GelatoRelay();
          const customNodeOptions = {
            rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
            chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
          };
          const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
            network: customNodeOptions,
          });
          const provider = new ethers.BrowserProvider(magic.rpcProvider);
          const signer = await provider.getSigner();
          const signerAddress = signer.address;
          let market = JSON.parse(Marketplace);
          //Pull the deployed contract instance
          let marketcontract = new ethers.Contract(
            market.address,
            market.abi,
            signer
          );
          let payload =
            await marketcontract.listItemUsingFiat.populateTransaction(
              nftTokenId
            );

          const relayRequest = {
            chainId: (await provider.getNetwork()).chainId,
            target: market.address,
            data: payload.data,
            user: signerAddress,
          };

          const RelayRequestOptions = {
            gasLimit: 8000000,
            // retries: 3,
          };

          await updateFiatListingPrice();

          const relayResponse = await relay.sponsoredCallERC2771(
            relayRequest,
            signer,
            process.env.VUE_APP_RELAYER_API_KEY,
            RelayRequestOptions
          );

          const isListingSuccessful = await checkTaskStatus(
            relayResponse.taskId
          );

          if (isListingSuccessful) {
            getCurrentNFT(nftTokenId);
            refetch();
            songRefetch();
            showModal.value = false;
            alert("NFT listed for sale");
          } else {
            throw new Error("Listing relay response failed");
          }
          step.value = 1;
          relistLoading.value = false;
        } catch (error) {
          relistLoading.value = false;
          console.log(error.message);
          console.log(error);
          alert("An error occurred please try again");
          console.log(error.message);
        }
      }
    }

    async function cancelListing() {
      if (!cancelLoading.value) {
        cancelLoading.value = true;
        if (isAdmin.value == true) {
          try {
            // Request account access if needed
            await window.ethereum.request({ method: "eth_requestAccounts" });
            // Get the user's accounts
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const address = await signer.getAddress();
            // Check if the MetaMask address matches the expected address

            let market = JSON.parse(Marketplace);
            //Pull the deployed contract instance
            let marketcontract = new ethers.Contract(
              market.address,
              market.abi,
              signer
            );

            const expectedAddress = process.env.VUE_APP_WALLET_ADDRESS;
            if (address.toLowerCase() !== expectedAddress.toLowerCase()) {
              alert(
                "Login failed. MetaMask address does not match admin address."
              );
              clearCookies();
            }

            let cancelPayload = await marketcontract.cancelListing(nftTokenId);
            console.log(cancelPayload);
            if (cancelPayload) {
              getCurrentNFT(nftTokenId);
              refetch();
              showModal.value = false;
              alert("NFT no longer up for sale");
            } else {
              throw new Error("Listing relay response failed");
            }
            step.value = 1;
            cancelLoading.value = false;
          } catch ({ error }) {
            alert("Error please try again.");
          }
        } else {
          try {
            const relay = new GelatoRelay();
            const customNodeOptions = {
              rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
              chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
            };
            const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
              network: customNodeOptions,
            });
            const provider = new ethers.BrowserProvider(magic.rpcProvider);
            const signer = await provider.getSigner();
            const signerAddress = signer.address;
            let market = JSON.parse(Marketplace);
            //Pull the deployed contract instance
            let marketcontract = new ethers.Contract(
              market.address,
              market.abi,
              signer
            );

            let cancelPayload =
              await marketcontract.cancelListing.populateTransaction(
                nftTokenId
              );

            const cancelRelayRequest = {
              chainId: (await provider.getNetwork()).chainId,
              target: market.address,
              data: cancelPayload.data,
              user: signerAddress,
            };

            const cancelRelayResponse = await relay.sponsoredCallERC2771(
              cancelRelayRequest,
              signer,
              process.env.VUE_APP_RELAYER_API_KEY
            );

            const isCancelListingSuccessful = await checkTaskStatus(
              cancelRelayResponse.taskId
            );

            if (isCancelListingSuccessful) {
              getCurrentNFT(nftTokenId);
              refetch();
              showModal.value = false;
              alert("NFT no longer up for sale");
            } else {
              throw new Error("Listing relay response failed");
            }
            step.value = 1;
            cancelLoading.value = false;
          } catch (error) {
            cancelLoading.value = false;
            alert("An error occurred please try again");
            console.log(error.message);
          }
        }
      }
    }

    const isOwner = computed(() => {
      if (nftMarketplaceDetails.value && accounts.value) {
        if (nftOwner.value == accounts.value.toLowerCase()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const isSeller = computed(() => {
      if (nftMarketplaceDetails.value && accounts.value) {
        if (nftMarketplaceDetails.value.seller == accounts.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const isListedForSale = computed(() => {
      if (nftMarketplaceDetails.value) {
        if (nftMarketplaceDetails.value.listedForSale == true) {
          return true;
        } else {
          return false;
        }
      }
    });

    const isMainLoading = computed(() => {
      if (currentNftLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    // async function getNFTsTransfers(token_id) {
    //   let NFTcontractData = JSON.parse(CreateNft);
    //   let address = NFTcontractData.address;
    //   const chain = EvmChain.MUMBAI;

    //   const response = await Moralis.EvmApi.nft.getNFTTransfers({
    //     chain: chain,
    //     format: "decimal",
    //     address: address,
    //     tokenId: token_id,
    //   });
    //   // console.log(response.toJSON());
    //   var data = response.toJSON();
    //   function weiToMatic(weiValue) {
    //     const maticValue = ethers.utils.formatUnits(weiValue, 18);
    //     return maticValue;
    //   }
    //   // Map over the results array and convert the value field to Matic
    //   const ownersWithMaticValue = data.result.map((owner) => ({
    //     ...owner,
    //     value: weiToMatic(owner.value),
    //   }));

    //   owners.value = ownersWithMaticValue;
    //   console.log(owners.value);
    // }

    const calculatePayout = (rate) => {
      const payout =
        (royaltyPercentage.value / 100) * rate * numberOfStreams.value * usdzar;
      return payout.toFixed(2);
    };

    const calculateTotalPayout = computed(() => {
      return Object.values(streamingRates)
        .reduce((acc, rate) => {
          return acc + parseFloat(calculatePayout(rate));
        }, 0)
        .toFixed(2);
    });

    const getPlatformImage = (platform) => {
      // Map each streaming platform to its corresponding image path
      const imageMap = {
        "Tidal Music":
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tidal.svg",
        "Apple Music":
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/apple.png",
        "Amazon Music":
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/amazon.png",
        Spotify:
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/spotify.png",
        "YouTube Music":
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/Youtube.png",
        Pandora:
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/pandora.png",
        Deezer:
          "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/deezer.png",
      };

      return imageMap[platform] || "/path/to/default-image.png";
    };

    async function getCurrentNFT(token_id) {
      currentNftLoading.value = true;
      try {
        const provider = new ethers.JsonRpcProvider(
          process.env.VUE_APP_ALCHEMY_API_URL
        );
        let NFTcontractData = JSON.parse(CreateNft);
        let market = JSON.parse(Marketplace);
        let nftcontractaddress = NFTcontractData.address;

        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );
        nftMarketplaceDetails.value = await contract.getListing(
          parseInt(token_id)
        );
        // price.value = ethers.utils.formatUnits(
        //   nftMarketplaceDetails.value.price,
        //   18
        // );
        price.value = nftMarketplaceDetails.value.price;
        console.log(nftMarketplaceDetails.value);
        const ownerResponse = await alchemy.nft.getOwnersForNft(
          nftcontractaddress,
          token_id
        );
        const metedataResponse = await alchemy.nft.getNftMetadata(
          nftcontractaddress,
          token_id,
          {}
        );
        nftOwner.value = ownerResponse.owners[0];
        metadata.value = metedataResponse.rawMetadata;
        console.log(nftOwner.value);
        console.log(metadata.value);
        const color = await prominent(metadata.value.image, {
          amount: 3,
          format: "hex",
        });
        if ((color[2] = "#ffffff")) {
          bannerBackground.value = color[1];
        } else {
          bannerBackground.value = color[2];
        }
        currentNftLoading.value = false;
      } catch (error) {
        currentNftLoading.value = false;
        console.log(error);
        alert("Error please reload page");
      }
    }

    return {
      isAuthenticated,
      redirectToLogin,
      mediaUrl,
      isMainLoading,
      mainLoading,
      mainArtist,
      bannerBackground,
      songBackendData,
      featuredArtists,
      step,
      payfastURL,
      currentTitle,
      nftOwner,
      metadata,
      updateCard,
      play,
      tab,
      buyloading,
      nftMarketplaceDetails,
      owners,
      isOwner,
      price,
      sellPrice,
      relistLoading,
      showModal,
      listNFT,
      isListedForSale,
      cancelListing,
      cancelLoading,
      email,
      accounts,
      userId,
      nftTokenId,
      signature,
      nftBackendId,
      refetch,
      paySignature,
      getCurrentNFT,
      isSeller,
      approveNFT,
      approveLoading,
      userAuthToken,
      mainArtistPicture,
      royaltyPercentage,
      streamingRates,
      numberOfStreams,
      calculatePayout,
      calculateTotalPayout,
      getPlatformImage,
      hashedId,
      backendUrl,
      collectionId,
      updateFiatListingPrice,
      payfastPhrase,
      payfastMerchantKey,
      payfastMerchantId,
      songLink,
    };
  },
};
</script>
<style scoped>
.p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.mainContainer {
  margin-top: -150px;
}
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}
.platform-image {
  width: 60px;
  border-radius: 50%;
  margin-right: 8px;
}
.songPlayer {
  width: 560px;
  height: 315px;
}
.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}

.contentLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.link-no-style {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
}

.artistContainer {
  display: flex;
  gap: 10px;
}

.custom-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
  /* Optionally, you can remove other link styles like hover and focus effects */
}

.detailsContainer {
  padding-left: 15px;
  padding-right: 15px;
}
.contentContainer {
  min-height: 100vh;
}

.subHeading {
  color: rgb(255, 123, 0);
  /* color: rgb(17, 169, 235); */
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

.ownerAddressText {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.dateText {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.artistImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.socialIcon {
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.subText {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.backgroundGradient {
  width: 100%;
  height: auto;
  padding-top: 110px;
}

.videodiv {
  z-index: -9;
}

.playcard {
  background: #0d0d0d;
  border-radius: 32px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.ownerIconBackground {
  background-color: white;
  width: 15px;
  height: 15px;
  margin-left: -30px;
}

.gradientColor {
  color: #a259ff;
}

.playbutton {
  font-size: 60px;
}

.songImage {
  height: 340px;
  width: 75%;
  object-fit: cover;
}

.playerColor {
  height: 4px;
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

.royaltystats {
  width: 100%;
  background: rgba(59, 59, 59, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.tabStyle {
  font-size: 25px;
  color: white;
}

.salesTab {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
}
.priceColor {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
}

.buyBtn {
  font-size: 20px;
  /* padding-top: 0; */
  /* padding-bottom: 0; */
  height: 80px;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 100%;
    height: auto;
  }

  .buyBtn {
    width: 320px;
  }

  .dateText {
    font-size: 14px;
    width: auto;
  }

  .songPlayer {
    width: 300px;
    height: 315px;
    margin: auto; /* This centers the iframe horizontally */
    display: flex;
    justify-content: center; /* This centers the iframe horizontally */
  }
}
</style>
