import { createWebHistory, createRouter } from "vue-router";
import HomePage from "../pages/HomePage.vue";
import ExplorePage from "../pages/ExplorePage.vue";
import NFTPage from "../pages/NFTPage.vue";
import MintPage from "../pages/MintPage.vue";
import CollectionPage from "../pages/CollectionPage.vue";
import ArtistPage from "../pages/ArtistPage.vue";
// import RegistrationPage from "../pages/RegistrationPage.vue";
import AccountPage from "../pages/AccountPage.vue";
// import AdminPage from "../pages/AdminPage.vue";
import CollectionNFTPage from "../pages/CollectionNFTPage.vue";
// import RoyaltyDistributionPage from "../pages/RoyaltyDistributionPage.vue";
import CancelPage from "../pages/CancelPage.vue";
import NotifyPage from "../pages/NotifyPage.vue";
import SuccessPage from "../pages/SuccessPage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import AdminLoginPage from "../pages/Authentication/AdminLoginPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import PasswordReset from "../pages/Authentication/PasswordReset.vue";
import ForgotPassword from "../pages/Authentication/ForgotPassword.vue";
import TermsConditions from "../pages/Documents/TermsConditions.vue";
import RegisterArtist from "../pages/Authentication/RegisterArtist.vue";
import TicketsHomePage from "@/pages/Tickets/TicketsHomePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    // meta: {isHomepage: true},
  },

  {
    path: "/tickets",
    name: "TicketsHome",
    component: TicketsHomePage
  },

  {
    path: '/terms',
    name: 'terms',
    component: TermsConditions,
  },

  {
    path: "/authentication/register",
    name: "Register",
    component: RegisterPage,
  },

  {
    path: "/authentication/login",
    name: "Login",
    component: LoginPage,
  },

  {
    path: "/authentication/register_artist",
    name: "RegisterArtist",
    component: RegisterArtist,
  },

  {
    path: "/admin/authentication/login",
    name: "AdminLoginPage",
    component: AdminLoginPage,
  },

  {
    path: "/authentication/passwordreset/:token",
    name: "PasswordReset",
    component: PasswordReset,
  },

  {
    path: "/authentication/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },


  {
    path: "/explore",
    name: "Explore",
    component: ExplorePage,
  },

  {
    path: "/cancel",
    name: "CancelPayemnt",
    component: CancelPage,
  },

  {
    path: "/notify",
    name: "NotifyPayemnt",
    component: NotifyPage,
  },

  {
    path: "/success/:hashId/:nftTokenId",
    name: "SuccessPayemnt",
    component: SuccessPage,
  },


  {
    path: "/collection/:id/:nftBackendId/:nftTokenId",
    name: "NFT",
    component: NFTPage,
  },


  {
    path: "/collection",
    name: "Collection",
    component: CollectionPage,
  },
  {
    path: "/collection/:id",
    name: "CollectionNFTPage",
    component: CollectionNFTPage,
  },
  {
    path: "/artist/:id",
    name: "Artist",
    component: ArtistPage,
  },
  // {
  //   path: "/artist/registration",
  //   name: "Registration",
  //   component: RegistrationPage,
  // },
  {
    path: "/account/:id",
    name: "AccountPage",
    component: AccountPage,
  },

  // {
  //   path: "/admin",
  //   name: "AdminPage",
  //   component: AdminPage,
  // },
    {
    path: "/admin/create",
    name: "Create",
    component: MintPage,
  },

  // {
  //   path: "/admin/royalty",
  //   name: "RoyaltyDistribution",
  //   component: RoyaltyDistributionPage,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;