<template>
  <v-app>
    <v-main class="appbackground">
      <div v-if="isMainLoading" class="mainProgressCircle">
        <v-progress-circular
          indeterminate
          color="rgb(255, 123, 0)"
          :size="50"
        ></v-progress-circular>
      </div>

      <v-container v-if="!isMainLoading && isVerified" class="contentContainer">
        <div class="text-center my-14">
          <p class="text-white text-h3">Congratulations</p>
          <p class="text-white text-h5">
            Your purchase was successful, you now own a streaming royalty share
            of {{ songBackendData.name }}
          </p>
        </div>
        <div class="d-flex justify-center align-center">
          <img
            class="songImage mx-4"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/congratulations.gif"
            alt="logo"
          />
          <img
            class="songImage mx-4"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/congratulations.gif"
            alt="logo"
          />
        </div>
        <div class="mx-auto text-center nftContainer">
          <NFTCard class="my-1 mx-auto" :nftData="songBackendData" />
        </div>
        <div class="d-flex justify-center align-center nftContainer">
          <img
            class="songImage mx-4"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/congratulations.gif"
            alt="logo"
          />
          <img
            class="songImage mx-4"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/congratulations.gif"
            alt="logo"
          />
        </div>
      </v-container>

      <v-container v-if="!isVerified" class="contentContainer">
        <div class="text-center my-14">
          <p class="text-white text-h3">404 page not found</p>
        </div>
      </v-container>
      <BottomFooter class="footer" />
    </v-main>
  </v-app>
</template>

<script>
import BottomFooter from "../components/BottomFooter.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useRoute } from "vue-router";
import NFTCard from "../components/NFTCard.vue";
const crypto = require("crypto");

export default {
  name: "SuccessPage",

  components: {
    BottomFooter,
    NFTCard,
  },

  setup() {
    const route = useRoute();
    const nftTokenId = route.params.nftTokenId;
    const hashId = route.params.hashId;

    const songBackendData = ref({});
    const featuredArtists = ref([]);
    const mainArtist = ref({});
    const mainArtistPicture = ref(null);

    const hashedId = crypto
      .createHash("md5")
      .update(nftTokenId + "successpage")
      .digest("hex");

    console.log(hashedId);
    console.log(hashId);


    const {
      result: songResult,
      loading: songLoading,
      error: songError,
      refetch: songRefetch,
    } = useQuery(
      gql`
        query dropsByNfttokenid($id: Int!) {
          dropsByNfttokenid(id: $id) {
            id
            name
            nfttokenid
            image
            price
            royaltyType
            royaltyPercentage
            collection {
              id
              artist {
                id
                name
                user {
                  profilePicture
                }
              }
            }
          }
        }
      `,
      {
        id: Number(nftTokenId),
      }
    );

    watch(songResult, (value) => {
      songBackendData.value = value.dropsByNfttokenid[0];
      mainArtist.value = value.dropsByNfttokenid[0].collection.artist;
      mainArtistPicture.value = mainArtist.value.user.profilePicture;
      console.log(songBackendData.value);
      console.log(mainArtist.value);
      console.log(mainArtistPicture.value);
    });

    const isMainLoading = computed(() => {
      if (songLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const isVerified = computed(() => {
      if (hashedId == hashId) {
        return true;
      } else {
        return false;
      }
    });

    return {
      songBackendData,
      mainArtist,
      mainArtistPicture,
      isMainLoading,
      isVerified
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.titleText {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}
.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}
.contentContainer {
  position: relative;
  z-index: 9;
  flex: 1;
  min-height: 100vh;
}
.songImage {
  height: 150px;
  width: 150px;
}
.nftContainer {
  margin-top: -80px;
}

.subtitleText {
  font-size: 30px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}
/* .appbackground {
    background-color: #11001d;
  } */
</style>
