<template>
  <v-main>
    <div class="mainContainer">
      <div v-if="loading" class="mainProgressCircle">
        <v-progress-circular
          indeterminate
          color="rgb(255, 123, 0)"
          :size="50"
        ></v-progress-circular>
      </div>
      <!-- <div class="backgroundGradient"></div> -->
      <div v-if="error">
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-alert type="error" outlined>
              {{ error.message }}
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <div v-else-if="collectionDetails && !loading && !error">
        <div class="detailsContainer">
          <div class="mx-auto text-center">
            <!-- Embedded YouTube video -->
            <iframe
              class="songPlayer"
             
              :src="songLink"
              :title="collectionDetails.name"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <!-- <img class="songImage" :src="imageUrl" alt="logo" />
            <div class="d-flex justify-center align-center">
              <a :href="songLink" target="_blank" class="mt-2">
                <v-btn color="success"> Listen on Spotify </v-btn>
              </a>
            </div> -->
            <!-- <iframe
              width="560"
              height="315"
              :src="youtubeUrl"
              frameborder="0"
              allowfullscreen
            ></iframe> -->
          </div>
          <p class="titleText">{{ collectionDetails.name }}</p>
          <!-- <p class="text-white subtitleText2">{{ collectionDetails.about }}</p> -->
          <p class="subtitleText mt-5">
            Available {{ collectionDetails.name }} song NFTs to purchase from
            Bronze, Gold and platinum edition
          </p>
        </div>

        <v-card max-width="1200" class="mx-auto mt-8" flat color="transparent">
          <v-tabs centered center-active color="white" v-model="tab">
            <v-tab class="tabStyle">Bronze</v-tab>
            <v-tab class="tabStyle">Gold</v-tab>
            <v-tab class="tabStyle">Platinum</v-tab>
          </v-tabs>
        </v-card>
        <v-card max-width="1500" class="mx-auto" flat color="transparent">
          <v-tabs-items v-model="tab" class="mt-4">
            <v-tab-item v-if="tab == 0">
              <div v-if="!loading" class="nftcollection-grid mt-4">
                <div v-for="nft in filteredNfts" :key="nft">
                  <v-lazy
                    :min-height="200"
                    :options="{ threshold: 0.5 }"
                    transition="fade-transition"
                  >
                    <NFTCard class="my-1" :nftData="nft" />
                  </v-lazy>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item v-if="tab == 1">
              <div v-if="!loading" class="nftcollection-grid mt-4">
                <div v-for="nft in filteredNfts" :key="nft">
                  <v-lazy
                    :min-height="200"
                    :options="{ threshold: 0.5 }"
                    transition="fade-transition"
                  >
                    <NFTCard class="my-1" :nftData="nft" />
                  </v-lazy>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item v-if="tab == 2">
              <div v-if="!loading" class="nftcollection-grid mt-4">
                <div v-for="nft in filteredNfts" :key="nft">
                  <v-lazy
                    :min-height="200"
                    :options="{ threshold: 0.5 }"
                    transition="fade-transition"
                  >
                    <NFTCard class="my-1" :nftData="nft" />
                  </v-lazy>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>

    <BottomFooter class="footer" />
  </v-main>
</template>

<script>
import { watch } from "vue";
import NFTCard from "../components/NFTCard.vue";
import PlayCard from "../components/PlayCard.vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { ref, onMounted, computed } from "vue";
import BottomFooter from "../components/BottomFooter.vue";
import { useRoute } from "vue-router";

export default {
  name: "CollectionNFTPage",

  components: {
    NFTCard,
    BottomFooter,
    PlayCard,
  },

  setup() {
    const route = useRoute();
    const nftCollections = ref([]);
    const collectionDetails = ref({});
    const imageUrl = ref(null);
    const tab = ref(null);
    const songLink = ref(null);
    const youtubeUrl = ref(
      `https://www.youtube.com/watch?v=yZwbJp7S7Fo&list=OLAK5uy_mHL5J_sKBMYUp4cT7wf7jNkmTHNjWy8Kw`
    );
    onMounted(() => {
      window.scrollTo(0, 0);
      refetch();
    });
    const { result, loading, error, refetch } = useQuery(
      gql`
        query dropsByCollection($collection: Int!) {
          dropsByCollection(collection: $collection) {
            id
            name
            image
            nfttokenid
            price
            royaltyPercentage
            royaltyType
            edition
            collection {
              id
              name
              image
              link
            }
          }
        }
      `,
      {
        collection: Number(route.params.id),
      }
    );

    watch(result, (value) => {
      nftCollections.value = value.dropsByCollection;
      collectionDetails.value = value.dropsByCollection[0].collection;
      if (collectionDetails.value) {
        songLink.value = collectionDetails.value.link;
      }
      imageUrl.value =
        process.env.VUE_APP_MEDIA_API_URL + collectionDetails.value.image;
    });

    const filteredNfts = computed(() => {
      if (!nftCollections.value || nftCollections.value.length === 0) {
        return [];
      }
      const categories = ["BRONZE", "GOLD", "PLATINUM"];
      const selectedCategory = categories[tab.value];
      return nftCollections.value.filter((song) => {
        return song.edition == selectedCategory;
      });
    });

    return {
      nftCollections,
      collectionDetails,
      refetch,
      loading,
      imageUrl,
      filteredNfts,
      youtubeUrl,
      tab,
      error,
      songLink,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.mainContainer {
  height: auto;
}
.collectionImage {
  height: 200px;
  width: 200px;
}
.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}

.songPlayer {
  width: 560px;
  height: 315px;
}

.tabStyle {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.songImage {
  width: 300px;
  height: 300px;
  border-radius: 8px;
}

.appIcons {
  height: 50px;
  width: 50px;
  color: greenyellow;
  /* filter: grayscale(100%); */
}

.gridContainer {
  max-width: 1500px;
}

.footer {
  margin-top: 300px;
}

.footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  /* padding: 20px 0; */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  z-index: 999;
}

.detailsContainer {
  margin-top: 60px;
  text-align: center;
  z-index: 10;
}

.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 90%;
  margin-inline: auto;
}

@media screen and (min-width: 370px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    /* padding-left: 10px; */
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.titleText {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: rgb(255, 123, 0);
  position: relative;
  z-index: 1;
}

.subtitleText {
  font-size: 16px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: rgb(240, 240, 240);
  position: relative;
  z-index: 1;
}

.subtitleText2 {
  font-size: 28px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .titleText {
    font-size: 40px;
  }
  .subtitleText {
    font-size: 14px;
  }
  .detailsContainer {
    margin-top: 0px;
  }

  .songPlayer {
    width: 300px;
    height: 315px;
  }
}
</style>
