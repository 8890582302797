<template>
  <div class="app-background">
    <div class="backgroundGradient"></div>
    <div class="content-wrapper">
      <BannerSection class="banner-style" />

      <section class="music-platforms">
        <div class="text-center text-h5 text-grey mb-5">
          Available on all streaming platforms
        </div>
        <v-container>
          <v-row justify="center" align="center">
            <v-col
              v-for="platform in musicPlatforms"
              :key="platform.name"
              cols="6"
              sm="3"
              md="3"
            >
              <a
                :href="platform.link"
                target="_blank"
                rel="noopener noreferrer"
                class="platform-link"
              >
                <div class="platform-icon">
                  <v-img :src="platform.icon" height="60" contain />
                  <p class="platform-name">{{ platform.name }}</p>
                </div>
              </a>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="recent-content my-3">
        <RecentSongs class="content-section mx-auto py-4" />
        <RecentDrops class="content-section mx-auto py-4" />
      </section>

      <section class="about-gummint">
        <v-container>
          <h2 class="section-title">Welcome to Gummint</h2>
          <v-row align="center" justify="center" class="feature-row">
            <v-col
              v-for="(feature, index) in features"
              :key="index"
              cols="12"
              md="4"
              class="text-center feature-col"
            >
              <div class="feature-card">
                <v-icon
                  size="100"
                  :color="feature.color"
                  class="feature-icon"
                  >{{ feature.icon }}</v-icon
                >
                <h3 class="feature-title">{{ feature.title }}</h3>
                <p class="feature-text">{{ feature.text }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="info-banner">
        <v-container>
          <h2 class="section-title">How It Works</h2>
          <v-row justify="center">
            <v-col
              v-for="info in infoCards"
              :key="info.title"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card class="info-card text-white" elevation="5">
                <v-img :src="info.image" height="200" contain />
                <v-card-title>{{ info.title }}</v-card-title>
                <v-card-text>{{ info.text }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="artist-showcase">
        <v-container>
          <h2 class="section-title">Collaborated Artists</h2>
          <v-row justify="center">
            <v-col
              v-for="artist in artists"
              :key="artist.name"
              cols="6"
              sm="3"
              md="3"
            >
              <router-link :to="artist.link" class="artist-link">
                <div class="artist-avatar-wrapper">
                  <img :src="artist.image" class="artist-image" />
                </div>
                <p class="artist-name">{{ artist.name }}</p>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="nft-tickets my-10">
        <v-container>
          <h2 class="section-title">Exclusive NFT Event Tickets</h2>
          <v-row align="center" justify="center" class="nft-ticket-content">
            <v-col cols="12" md="6">
              <v-img
                src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/ticket+2.png"
                class="ticket-image"
              />
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="ticket-subtitle">More Than Just a Ticket</h3>
              <ul class="ticket-features">
                <li>Unique digital collectibles</li>
                <li>Exclusive event access</li>
                <li>Special perks and experiences</li>
                <li>Potential for future value</li>
              </ul>
              <v-btn color="primary" x-large to="/tickets" class="mt-4">
                Learn more
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="video-section">
        <v-container>
          <v-row align="center">
            <v-col cols="12" md="6">
              <video controls class="video-player">
                <source
                  src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/videos/gummintdrop.mp4"
                  type="video/mp4"
                />
              </video>
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="video-title">Join the Music NFT Revolution</h3>
              <p class="video-text">
                Don't miss out on the future of music. Discover the hottest
                music NFT of the year: "Ungathemba" by Gummint x Belo Selo.
                Collect it now and be part of music history!
              </p>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <BottomFooter class="footer" />
    </div>
  </div>
</template>

<script>
import BannerSection from "../components/BannerSection.vue";
import RecentDrops from "../components/RecentDrops.vue";
import RecentSongs from "../components/RecentSongs.vue";
import BottomFooter from "../components/BottomFooter.vue";

export default {
  name: "HomePage",

  components: {
    BannerSection,
    RecentDrops,
    RecentSongs,
    BottomFooter,
  },

  data() {
    return {
      features: [
        {
          icon: "mdi-music-note",
          color: "#FF6B6B",
          title: "For Artists",
          text: "Tokenize your music, reach more fans, and earn fairer royalties.",
        },
        {
          icon: "mdi-account-group",
          color: "#4ECDC4",
          title: "For Fans",
          text: "Own a piece of your favorite music and support artists directly.",
        },
        {
          icon: "mdi-chart-line",
          color: "#FFA500",
          title: "For Collectors",
          text: "Invest in unique music NFTs with potential for growth.",
        },
      ],

      musicPlatforms: [
        {
          name: "Apple Music",
          icon: "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/apple-music.svg",
          link: "https://music.apple.com/us/artist/gummint/1726987825",
        },
        {
          name: "Spotify",
          icon: "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/spotify.svg",
          link: "https://open.spotify.com/artist/4fvi8G0Fz8Sw8WBb52Ph16",
        },
        {
          name: "YouTube Music",
          icon: "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/youtubemusic.svg",
          link: "https://www.youtube.com/channel/UCM-zDszR8JzN9DwFsaaFpwg",
        },
        {
          name: "Tidal",
          icon: "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tidal.svg",
          link: "https://tidal.com/",
        },
      ],
      infoCards: [
        {
          title: "Fractionalized Ownership",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/pizza.svg",
          text: "Artists can divide their music into smaller, tradable shares, similar to stocks in a company. This allows for a broader range of investors and supporters to share in potential earnings.",
        },
        {
          title: "Music Tokenization",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tokenization.svg",
          text: "We assist artists in creating unique digital assets representing their work on the blockchain, transforming songs and albums into tradeable tokens.",
        },
        {
          title: "Distribution",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/distribution.svg",
          text: "We distribute your music to preferred digital streaming platforms and help get your art to all major web3 NFT platforms through blockchain technology.",
        },
        {
          title: "Royalty Management",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/royalty.svg",
          text: "Our platform provides transparent and automated royalty management using blockchain technology, ensuring fair distribution of earnings to all investors.",
        },
      ],
      artists: [
        {
          name: "Belo Salo",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/belo.jpg",
          link: "/artist/2",
        },
        {
          name: "J-6ix",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/j6ix.png",
          link: "/artist/3",
        },
        {
          name: "Hypaphonik",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/hypaphonikpic.jpg",
          link: "/artist/4",
        },
        {
          name: "Wapojije",
          image:
            "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/Wapojijepic.jpg",
          link: "/artist/5",
        },
      ],
    };
  },
};
</script>
<style scoped>
.app-background {
  /* background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%); */
  min-height: 100vh;
  color: #ffffff;
}

.content-wrapper {
  position: relative;
  z-index: 2;
}

.nft-tickets {
  padding: 7rem 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  margin-top: 35px;
}

.about-gummint {
  padding: 4rem 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}
.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-row,
.platform-row {
  margin-top: 2rem;
}

.feature-col,
.platform-col {
  padding: 1rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-icon {
  transform: scale(1.1);
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ffffff;
}

.feature-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0e0e0;
}

@media (max-width: 960px) {
  .section-title {
    font-size: 2rem;
  }

  .feature-title {
    font-size: 1.3rem;
  }

  .feature-text {
    font-size: 1rem;
  }
}

.nft-ticket-content {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.ticket-image {
  border-radius: 10px;
  height: 350px;
  width: 100%;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
}

.ticket-subtitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ff6b6b;
}

.ticket-features {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 2rem;
}

.ticket-features li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #e0e0e0;
}

.ticket-features li::before {
  content: "✓";
  color: #4facfe;
  margin-right: 0.5rem;
}

@media (max-width: 960px) {
  .feature-title {
    font-size: 1.3rem;
  }

  .feature-text,
  .ticket-features li {
    font-size: 1rem;
  }

  .ticket-subtitle {
    font-size: 1.5rem;
  }
}

.backgroundGradient {
  position: absolute;
  width: 100%;
  height: 1000px;
  top: -100px;
  background: url("https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/homeBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-style {
  margin-bottom: 4rem;
}

.music-platforms {
  padding: 2rem 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.platform-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.platform-icon {
  text-align: center;
  transition: transform 0.3s ease;
}

.platform-icon:hover {
  transform: translateY(-5px);
}

.platform-name {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #a0a0a0;
}

.recent-content {
  padding: 4rem 0;
}

.content-section {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-card {
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.artist-showcase {
  padding: 4rem 0;
  /* background: rgba(255, 255, 255, 0.03); */
  margin-top: 35px;
  margin-bottom: 35px;
}

.artist-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.artist-link:hover {
  transform: scale(1.05);
}

.artist-avatar-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.artist-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artist-name {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.video-section {
  padding: 4rem 0;
}

.video-player {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.video-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.video-text {
  font-size: 1.1rem;
  line-height: 1.6;
}

.footer {
  margin-top: 4rem;
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 960px) {
  .section-title {
    font-size: 2rem;
  }

  .video-title {
    font-size: 1.5rem;
  }

  .video-text {
    font-size: 1rem;
  }
}
</style>
