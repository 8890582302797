<template>
    <div class="glassmorphism">
      <p class="headingText text-center">Your music NFTs</p>
      <p v-if="allNFTs.length != 0" class="nftSectionInfo mx-auto mt-2">
        Below are the purchased music streaming royalties click to view more information
      </p>
      <div v-if="!loading" class="nftcollection-grid my-4">
        <div v-for="nft in allNFTs" :key="nft">
          <NFTBlockchainCard class="mb-4" :nftData="nft" />
        </div>
      </div>
      <div v-if="allNFTs.length == 0" class="centeredTextContainer">
        <p class="emptyText mx-auto">
          You dont own any music NFTs, visit the explore page to view available NFTs from your favourite artists
        </p>
      </div>
      <div class="mb-4 d-flex justify-center">
        <v-btn class="subtitleText seeButtonBottom px-5" @click="$emit('explore')">Explore</v-btn>
      </div>
    </div>
  </template>
  
  <script>
  import NFTBlockchainCard from "../components/NFTBlockchainCard.vue";
  
  export default {
    name: "OwnedNFTs",
    components: {
      NFTBlockchainCard,
    },
    props: {
      allNFTs: Array,
    },
    emits: ['explore'],
  };
  </script>
  
  <style scoped>
  .glassmorphism {
    margin-top: 50px;
    height: auto;
    min-height: 700px;
  }
  
  .headingText {
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    color: rgb(255, 123, 0);
    z-index: 2;
  }
  
  .nftSectionInfo {
    max-width: 550px;
    font-size: 15px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: white;
  }
  
  .nftcollection-grid {
    display: grid;
    gap: 1.5rem;
    width: 90%;
    margin-inline: auto;
  }
  
  .centeredTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  
  .emptyText {
    max-width: 400px;
    font-size: 25px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: white;
  }
  
  .subtitleText {
    font-size: 18px;
    margin-top: 3px;
    font-family: "Poppins", sans-serif;
    color: white;
  }
  
  .seeButtonBottom {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    width: 180px;
    color: white;
    box-shadow: 0 0 2px #eee;
    border-radius: 25px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  }
  
  @media screen and (min-width: 380px) {
    .nftcollection-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (min-width: 800px) {
    .nftcollection-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (min-width: 1250px) {
    .nftcollection-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  </style>